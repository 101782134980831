export const setSeo = (
  strapiSeo?: {
    metaTitle?: string;
    metaDescription?: string;
    metaImage?: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
    metaSocial?: Array<{
      socialNetwork: string;
      title?: string;
      description?: string;
      image?: {
        data: {
          attributes: {
            url: string;
          };
        };
      };
    }>;
    metaRobots?: string;
    keywords?: string;
    hrefLang?: string;
    structuredData?: string;
    [key: string]: any;
  },
  strapiLocalizations?: {
    data: Array<{
      attributes: {
        locale: "de" | "en";
        slug?: string;
        Slug?: string;
      };
    }>;
  },
  ogType?: string,
) => {
  const { locale } = useI18n();
  const { path } = useRoute();
  const config = useRuntimeConfig();
  const localization = useLocalization();
  const apiBase = config.public.apiBase;
  const domains = config.public.domain;

  interface Seo {
    ogTitle?: string;
    ogDescription?: string;
    ogType?: string;
    ogImage?: string;
    ogUrl?: string;
    ogLocale?: string;
    twitterTitle?: string;
    twitterDescription?: string;
    twitterImage?: string;
    twitterUrl?: string;
    twitterCard?: string;
    robots?: string;
    keywords?: string;
  }

  const strapiToOpengaphMapping = {
    metaTitle: "ogTitle",
    metaDescription: "ogDescription",
    metaImage: "ogImage",
    metaRobots: "robots",
    keywords: "keywords",
  };

  const seo: Seo = {
    ogType: ogType || "website",
    ogLocale: locale.value,
  };

  interface Head {
    link: Array<{
      rel: string;
      hreflang?: string;
      href?: string;
      [key: string]: any;
    }>;
    meta: Array<{
      name?: string;
      content?: string;
      property?: string;
      [key: string]: any;
    }>;
    [key: string]: any;
  }

  const head: Head = {
    link: [],
    meta: [],
  };

  if (strapiSeo) {
    for (const [key, value] of Object.entries(strapiSeo)) {
      if (!value) continue;
      switch (key) {
        case "metaImage":
          if (value.data?.attributes?.url) {
            seo[strapiToOpengaphMapping[key]] =
              apiBase + value.data.attributes.url;
          }
          break;

        case "structuredData":
          const structuredData = value;
          structuredData["@context"] = "https://schema.org";
          head["script"] = {
            type: "application/ld+json",
            innerHTML: JSON.stringify(structuredData),
          };
          break;

        case "metaTitle":
          head["title"] = value;
          seo[strapiToOpengaphMapping[key]] = value;
          break;

        case "metaDescription":
          head["meta"].push({
            name: "description",
            content: value,
          });
          seo[strapiToOpengaphMapping[key]] = value;

        case "metaSocial":
          break;

        default:
          const mapping = strapiToOpengaphMapping[key];
          if (mapping) seo[mapping] = value;
      }
    }
  }

  if (strapiSeo?.metaSocial) {
    for (const social of strapiSeo.metaSocial) {
      if (social.socialNetwork && social.socialNetwork === "Twitter") {
        if (social.title) seo.twitterTitle = social.title;
        if (social.description) seo.twitterDescription = social.description;
        if (social.image?.data?.attributes?.url)
          seo.twitterImage = apiBase + social.image.data.attributes.url;
      }
    }
  } else {
    if (strapiSeo?.metaTitle) seo.twitterTitle = strapiSeo.metaTitle;
    if (strapiSeo?.metaDescription)
      seo.twitterDescription = strapiSeo.metaDescription;
    if (strapiSeo?.metaImage?.data?.attributes?.url)
      seo.twitterImage = apiBase + strapiSeo.metaImage.data.attributes.url;
  }

  if (domains[locale.value]) {
    seo["ogUrl"] = domains[locale.value] + path;
    head.link.push(
      {
        rel: "alternate",
        hreflang: locale.value,
        href: domains[locale.value] + path,
      },
      {
        rel: "canonical",
        href: domains[locale.value] + path,
      },
    );
  }

  if (strapiLocalizations?.data) {
    for (const strapiLocale of strapiLocalizations.data) {
      const domain = domains[strapiLocale.attributes?.locale];
      if (domain && localization.value[strapiLocale.attributes?.locale]) {
        head.link.push({
          rel: "alternate",
          hreflang: strapiLocale.attributes.locale,
          href:
            domain +
            "/" +
            localization.value[strapiLocale.attributes.locale].pathMatch,
        });
        head.meta.push({
          property: "og:locale:alternate",
          content: strapiLocale.attributes.locale,
        });
      }
    }
  }

  useSeoMeta(seo);
  useHead(head);
};
